import {
    HcLazyload
} from '@iris.interactive/handcook/public/scripts/components/lazyload/lazyload.component'
import Swiper
    from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'
import apiFetch
    from '@wordpress/api-fetch'

export default class HomeService {
    constructor() {
        if ($('.home').length > 0) {
            HomeService.header().then(() => {
                HomeService.strateInspirations()
                HomeService.strateSejours()
                HomeService.strateVisites()
                HomeService.strateAgenda()
                HomeService.strateMap()
                HomeService.strateSocial()
                HomeService.scrollReveal()
            })
        }
    }

    static async header() {
        const element = 'div[data-home-banner-slider]'
        if (element.length > 0 && !$('body').hasClass('eco-mode-active')) {
            const swiperKey = 'home_banner_swiper'
            const slideItem = '.header__banner__item > .header__banner__item__thumbnail'

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                slidesPerView: 1,
                spaceBetween: 0,
                speed: 1000,
                autoplay: {
                    delay: 5000,
                },
                effect: 'slide',
                pagination: {
                    el: '.header__banner__item__pagination',
                    clickable: true,
                },
                keyboard: true,
                loop: false,
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                on: {
                    activeIndexChange: function () {
                        const index = this.realIndex
                        const target = '.header__banner__item__bulletization__image'
                        $(target).removeClass('is-active').removeClass('is-prev')

                        if (this.realIndex > 0) {
                            for (let i = 0; i <= this.realIndex - 1; i++) {
                                $(target).eq(i).addClass('is-prev')
                            }
                        }
                        $(target).eq(this.realIndex).addClass('is-active')
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)

            $('.header__banner__item__bulletization__image').on('click', function () {
                const index = $(this).data('index')
                mySwiper.slideTo(index)
            })
        }
    }

    static strateInspirations() {
        HomeService.buildInspirationsSwiper()

        $('.strate__inspirations__category').on('click', function () {
            $('.strate__inspirations__category').removeClass('active')
            $(this).addClass('active')
            const categoryId = $(this).data('identifiant')

            $.ajax({
                url: IRISCollectionTheme.ajaxUrl,
                data: {
                    action: 'get_inspirations_posts',
                    datas: {
                        category_id: categoryId,
                    },
                },
                method: 'POST',
            }).done((response) => {
                $('[data-element="inspirations-swiper-container"]').html(response)
                HcLazyload.update()
                HomeService.buildInspirationsSwiper()
            })

            // mobile dropdown
            const dropDown = $('[data-trigger="category-dropdown-mobile"]')
            const currentCat = $('.strate__inspirations__categories__list .active').html()
            dropDown.removeClass('open')
            dropDown.find('.label').html(currentCat)
            $('.strate__inspirations__categories__list').removeClass('open')

            return false
        })

        $('[data-trigger="category-dropdown-mobile"]').on('click', function () {
            $(this).toggleClass('open')
            $('.strate__inspirations__categories__list').toggleClass('open')
        })
    }

    static async buildInspirationsSwiper() {
        const element = $('[data-element="inspirations-swiper-container"]')
        if (element.length > 0 && !element.is(':hidden')) {
            const swiperKey = 'home-inspirations-swiper'
            const slideItem = `[data-swiper="${swiperKey}"] > .custom-post-card`

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                slidesPerView: 1,
                spaceBetween: 10,
                speed: 1000,
                navigation: {
                    nextEl: `#swiper-${swiperKey}-next`,
                    prevEl: `#swiper-${swiperKey}-prev`,
                },
                loop: true,
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1000: {
                        slidesPerView: 2,
                        spaceBetween: 45,
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const countItems = $('.strate__inspirations__posts .custom-post-card').length
            if (countItems === 0) {
                $('.strate__inspirations__arrows').hide()
            } else {
                $('.strate__inspirations__arrows').show()
            }

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
        }
    }

    static async strateSejours() {
        const element = $('.strate__sejours__posts')
        if (element.length > 0 && !element.is(':hidden')) {
            const swiperKey = 'home-sejours-swiper'
            const slideItem = `[data-swiper="${swiperKey}"] > .custom-sejours-card`

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                slidesPerView: 1.25,
                spaceBetween: 10,
                speed: 1000,
                navigation: {
                    nextEl: `#swiper-${swiperKey}-next`,
                    prevEl: `#swiper-${swiperKey}-prev`,
                },
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    600: {
                        slidesPerView: 2.25,
                        spaceBetween: 20,
                    },
                    1000: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
        }
    }

    static async strateVisites() {
        const element = $('.strate__visites')
        if (
            element.length > 0 &&
            !element.is(':hidden') &&
            window.matchMedia('(max-width: 1000px)').matches
        ) {
            $('.strate__visites .custom-visites-card').wrapAll(
                '<div data-swiper="home-visites-swiper"></div>',
            )

            const swiperKey = 'home-visites-swiper'
            const slideItem = `[data-swiper="${swiperKey}"] > .custom-visites-card`

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                speed: 1000,
                navigation: {
                    nextEl: `#swiper-${swiperKey}-next`,
                    prevEl: `#swiper-${swiperKey}-prev`,
                },
                effect: 'slide',
                grabCursor: true,
                slidesPerView: 1.25,
                spaceBetween: 12,
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    600: {
                        slidesPerView: 2.25,
                        spaceBetween: 20,
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
        }
    }

    static async strateAgenda() {
        const element = $('.strate__agenda__events')
        if (element.length > 0 && !element.is(':hidden')) {
            const swiperKey = 'home-events-swiper'
            const slideItem = `[data-swiper="${swiperKey}"] > .custom-event-card`

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                speed: 1000,
                navigation: {
                    nextEl: `#swiper-${swiperKey}-next`,
                    prevEl: `#swiper-${swiperKey}-prev`,
                },
                effect: 'coverflow',
                grabCursor: true,
                centeredSlides: true,
                loop: true,
                slidesPerView: 'auto',
                coverflowEffect: {
                    rotate: 0,
                    stretch: -55,
                    modifier: 4,
                    slideShadows: false,
                },
                spaceBetween: 40,
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    600: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    1000: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
        }

        const element2 = $('.strate__agenda__agenda')
        if (
            element2.length > 0 &&
            !element2.is(':hidden') &&
            window.matchMedia('(max-width: 1000px)').matches
        ) {
            const swiperKey = 'home-agenda-swiper'
            const slideItem = `[data-swiper="${swiperKey}"] > .custom-agenda-card`

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                speed: 1000,
                navigation: {
                    nextEl: `#swiper-${swiperKey}-next`,
                    prevEl: `#swiper-${swiperKey}-prev`,
                },
                grabCursor: true,
                slidesPerView: 1.25,
                spaceBetween: 10,
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    600: {
                        slidesPerView: 2.25,
                        spaceBetween: 20,
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
        }
    }

    static async strateMap() {
        const element = $('.strate__map__slide')
        if (element.length > 0 && !element.is(':hidden')) {
            const swiperKey = 'home-map-swiper'
            const slideItem = `[data-swiper="${swiperKey}"] > .custom-activites-card`

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                speed: 1000,
                navigation: {
                    nextEl: `#swiper-${swiperKey}-next`,
                    prevEl: `#swiper-${swiperKey}-prev`,
                },
                effect: 'slide',
                grabCursor: true,
                slidesPerView: 1.25,
                spaceBetween: 12,
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    600: {
                        slidesPerView: 2.25,
                        spaceBetween: 20,
                    },
                    1000: {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                },
                on: {
                    activeIndexChange: function () {
                        const index = this.realIndex
                        const target = '.strate__map__map .incontournable'
                        $(target).removeClass('is-active')
                        $(target).eq(this.realIndex).addClass('is-active')
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)

            $('.strate__map__map .incontournable').on('click', function () {
                const index = $(this).data('element')
                mySwiper.slideTo(index - 1)
            })
        }
    }

    static strateSocial() {
        const $socialWall = $("[data-id='social-wall']")

        if ($socialWall.length > 0 && !$socialWall.is(':hidden')) {
            apiFetch({
                path: IRISCollectionCustomer.irisSsoInstagramPath,
                method: 'POST',
                data: {limit: 4},
            })
                .then((result) => {
                    $socialWall.html(result.render)
                    HcLazyload.update()
                })
                .catch((error) => {
                    console.error(IRISCollectionCustomer.irisSsoInstagramError)
                    console.error(error)
                })
        }
    }

    static scrollReveal() {
        const ratio = 0.1
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        }

        const handleIntersect = (entries, observer) => {
            for (const entry of entries) {
                if (entry.intersectionRatio >= ratio) {
                    entry.target.classList.remove('reveal')
                    observer.unobserve(entry.target)
                }
            }
        }

        if (!$('body').hasClass('eco-mode-active')) {
            document.documentElement.classList.add('reveal-loaded')
            if (!window.matchMedia('(max-width: 1000px)').matches) {
                const observer = new IntersectionObserver(handleIntersect, options)
                for (const el of $('.reveal')) {
                    observer.observe(el)
                }
            } else {
                for (let i = 1; i <= 10; i++) {
                    for (const el of $(`.reveal-${i}`)) {
                        el.classList.remove(`reveal-${i}`)
                    }
                }
            }
        }
    }
}
